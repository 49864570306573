import React from 'react';
import {ListHead} from '../../styled/ContentInfo';

const CatalizatorInfo = () => {
    return (
        <>
            <ListHead>
                Автомобіль вже давно не є розкішшю і їхня кількість збільшується. На жаль, їхній вплив на навколишнє
                середовище має згубний вплив.
                Інженери знайшли частковий вихід із положення впровадження норм екологічності для транспортних засобів.
                Досягається це шляхом встановлення каталізаторів та сажових фільтрів. Але з часом ці системи очищення
                вихлопу виходять з ладу і
                їх потрібно міняти, чистити в окремих випадках вирізати.
            </ListHead>
            <ListHead>
                Ми рекомендуємо їх міняти на нові, але не кожен автовласник може собі це дозволити. У такому випадку ми
                проводимо промивання фільтрів сажі і каталізаторів на спеціалізованому обладнанні. Коли каталізатор вже
                неможливо відновити, ми проводимо його видалення з подальшою прошивкою ЕБК, щоб автомобілем можна було
                користуватися далі. Навіть після такої процедури ми рекомендуємо встановити каталізатор з відкатом до
                заводської прошивки ЕБК.
            </ListHead>
        </>
    );
};

export default CatalizatorInfo;