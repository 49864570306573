import React from 'react';
import {ListHead} from '../../styled/ContentInfo';

const SteringInfo = () => {
    return (
        <>
            <ListHead>
                Кермовий механізм відповідає не тільки за напрямок руху автомобіля, а також є одним із ключових факторів, які відповідають за безпеку їзди.
                Якщо Ви відчуваєте, що з'явилися стуки в районі кермового механізму, збільшений люфт кермового колеса, гул насоса гідропідсилювача,
                значить відкладати відвідування автосервісу вже не можна.
                Ми здійснюємо дефектування і заміну всіх частин кермового механізму, а також ремонт гідравлічних кермових рейок.
            </ListHead>
        </>
    );
};

export default SteringInfo;