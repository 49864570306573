import React from 'react';

const GeoIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M3.95874 9.16666C3.95874 5.82995 6.66369 3.125 10.0004 3.125C13.3371 3.125 16.0421 5.82995 16.0421 9.16666C16.0421 10.2214 15.5059 11.5458 14.4048 12.9472C13.3714 14.2625 11.8776 15.5963 10.0004 16.7674C8.12324 15.5963 6.62944 14.2625 5.59603 12.9472C4.49497 11.5458 3.95874 10.2214 3.95874 9.16666ZM10.0004 1.875C5.97333 1.875 2.70874 5.13959 2.70874 9.16666C2.70874 10.6119 3.42252 12.2042 4.61313 13.7195C5.81304 15.2466 7.54079 16.7531 9.67885 18.0359L10.0004 18.2289L10.322 18.0359C12.46 16.7531 14.1878 15.2466 15.3878 13.7195C16.5783 12.2042 17.2921 10.6119 17.2921 9.16666C17.2921 5.13959 14.0275 1.875 10.0004 1.875ZM12.0837 8.33334C12.0837 9.48392 11.151 10.4167 10.0004 10.4167C8.84982 10.4167 7.91708 9.48392 7.91708 8.33334C7.91708 7.18274 8.84982 6.25 10.0004 6.25C11.151 6.25 12.0837 7.18274 12.0837 8.33334ZM13.3338 8.33334C13.3338 10.1743 11.8414 11.6667 10.0004 11.6667C8.15947 11.6667 6.66708 10.1743 6.66708 8.33334C6.66708 6.49239 8.15947 5 10.0004 5C11.8414 5 13.3338 6.49239 13.3338 8.33334Z"
            fill="currentColor"/>
    </svg>

  );
};

export default GeoIcon;