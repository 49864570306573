import React from 'react';

const TgIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_36_52)">
                <path d="M80 40C80 50.6087 75.7857 60.7828 68.2843 68.2843C60.7828 75.7857 50.6087 80 40 80C29.3913 80 19.2172 75.7857 11.7157 68.2843C4.21427 60.7828 0 50.6087 0 40C0 29.3913 4.21427 19.2172 11.7157 11.7157C19.2172 4.21427 29.3913 0 40 0C50.6087 0 60.7828 4.21427 68.2843 11.7157C75.7857 19.2172 80 29.3913 80 40ZM41.435 29.53C37.545 31.15 29.765 34.5 18.105 39.58C16.215 40.33 15.22 41.07 15.13 41.79C14.98 43.005 16.505 43.485 18.58 44.14L19.455 44.415C21.495 45.08 24.245 45.855 25.67 45.885C26.97 45.915 28.415 45.385 30.01 44.285C40.905 36.93 46.53 33.215 46.88 33.135C47.13 33.075 47.48 33.005 47.71 33.215C47.945 33.42 47.92 33.815 47.895 33.92C47.745 34.565 41.76 40.125 38.665 43.005C37.7 43.905 37.015 44.54 36.875 44.685C36.5667 45.0001 36.2533 45.3101 35.935 45.615C34.035 47.445 32.615 48.815 36.01 51.055C37.645 52.135 38.955 53.02 40.26 53.91C41.68 54.88 43.1 55.845 44.94 57.055C45.405 57.355 45.855 57.68 46.29 57.99C47.945 59.17 49.44 60.23 51.275 60.06C52.345 59.96 53.45 58.96 54.01 55.96C55.335 48.875 57.94 33.53 58.54 27.205C58.5766 26.6792 58.5548 26.151 58.475 25.63C58.4279 25.2096 58.2245 24.8223 57.905 24.545C57.45 24.2309 56.9077 24.0682 56.355 24.08C54.855 24.105 52.54 24.91 41.435 29.53Z" fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_36_52">
                    <rect width="80" height="80" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default TgIcon;