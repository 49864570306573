import React from 'react';

const DownloadIcon = () => {
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.09375 21.6562C1.38383 21.6562 1.66203 21.7715 1.86715 21.9766C2.07227 22.1817 2.1875 22.4599 2.1875 22.75V28.2188C2.1875 28.7989 2.41797 29.3553 2.8282 29.7655C3.23844 30.1758 3.79484 30.4062 4.375 30.4062H30.625C31.2052 30.4062 31.7616 30.1758 32.1718 29.7655C32.582 29.3553 32.8125 28.7989 32.8125 28.2188V22.75C32.8125 22.4599 32.9277 22.1817 33.1329 21.9766C33.338 21.7715 33.6162 21.6562 33.9062 21.6562C34.1963 21.6562 34.4745 21.7715 34.6796 21.9766C34.8848 22.1817 35 22.4599 35 22.75V28.2188C35 29.3791 34.5391 30.4919 33.7186 31.3123C32.8981 32.1328 31.7853 32.5938 30.625 32.5938H4.375C3.21468 32.5938 2.10188 32.1328 1.28141 31.3123C0.460936 30.4919 0 29.3791 0 28.2188V22.75C0 22.4599 0.115234 22.1817 0.320352 21.9766C0.52547 21.7715 0.803669 21.6562 1.09375 21.6562Z" fill="currentColor"/>
            <path d="M16.7256 25.9306C16.8272 26.0325 16.9479 26.1133 17.0808 26.1684C17.2137 26.2236 17.3561 26.252 17.5 26.252C17.6439 26.252 17.7863 26.2236 17.9192 26.1684C18.0521 26.1133 18.1728 26.0325 18.2744 25.9306L24.8369 19.3681C25.0422 19.1627 25.1576 18.8842 25.1576 18.5938C25.1576 18.3033 25.0422 18.0248 24.8369 17.8194C24.6315 17.614 24.3529 17.4986 24.0625 17.4986C23.772 17.4986 23.4935 17.614 23.2881 17.8194L18.5937 22.5159V3.28125C18.5937 2.99117 18.4785 2.71297 18.2734 2.50785C18.0683 2.30273 17.7901 2.1875 17.5 2.1875C17.2099 2.1875 16.9317 2.30273 16.7266 2.50785C16.5215 2.71297 16.4062 2.99117 16.4062 3.28125V22.5159L11.7119 17.8194C11.5065 17.614 11.2279 17.4986 10.9375 17.4986C10.647 17.4986 10.3685 17.614 10.1631 17.8194C9.95774 18.0248 9.84236 18.3033 9.84236 18.5938C9.84236 18.8842 9.95774 19.1627 10.1631 19.3681L16.7256 25.9306Z" fill="currentColor"/>
        </svg>

    );
};

export default DownloadIcon;