import React from 'react';
import {ListHead} from '../../styled/ContentInfo';

const OzonInfo = () => {
    return (
        <>
            <ListHead>
                Озон знищує 99% мікроорганізмів (бактерій, вірусів, мікробів), зокрема коронавіруси. Озонування салону
                автомобіля принесе неоціненну користь і в тих випадках, коли за відсутності яскраво виражених неприємних
                запахів відчувається «несвіжість» матеріалів. Озон швидко та гарантовано знищить мікроорганізми,
                суперечки плісняви та джерела запахів. Рекомендується проводити процедуру з кожним ТО та обов'язковою
                умовою є заміна фільтра салону
            </ListHead>
        </>
    );
};

export default OzonInfo;