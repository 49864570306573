import React from 'react';
import {ListHead} from '../../styled/ContentInfo';

const ConditionerInfo = () => {
    return (
        <>
            <ListHead>
                Важко уявити комфортне переміщення в автомобілі без системи кондиціювання. Щоб система працювала
                безвідмовно, необхідно проводити регламентне обслуговування, таке як заміна масла компресора
                кондиціонера кожні 3-5 років, залежно від умов використання.
            </ListHead>
            <ListHead>
                Також хладагент має корисний термін експлуатації і підлягає періодичній заміні. Якщо система
                кондиціонування Вашого автомобіля знизила ефективність роботи або зовсім перестала працювати, значить
                час відвідати автосервіс. Вихід хладагенту із системи свідчить про розгерметизацію магістралей і тягне
                за собою більш складний та дорогий ремонт (заміна компресора, конденсера, випарника). Насамперед ми
                визначаємо витоку, користуючись технологічним регламентом обслуговування холодильних систем. Іноді
                запущена система вимагає ретельного промивання спеціальними засобами та обладнанням.
            </ListHead>
            <ListHead>
                Після всіх операцій заправка системи кондиціювання є фінальним
                етапом регламентного обслуговування.
            </ListHead>
        </>
    );
};

export default ConditionerInfo;